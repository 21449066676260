'use client';

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  useDisclosure,
} from '@nextui-org/react';
import { Loader2 } from 'lucide-react';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useCallback, useState } from 'react';
import GroupPricingInfo from './GroupPricingInfo';
import BaggageTab from '@/components/shared/flight/BaggageTab';
import FareInfoTab from '@/components/shared/flight/FareInfoTab';
import FlightTab from '@/components/shared/flight/FlightTab';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { BiChevronDown } from '@/Icons/bi';
import { FaPersonWalkingLuggage } from '@/Icons/fa6';
import { MdAirlineSeatReclineNormal } from '@/Icons/md';
import AirlinesLogoName from '@/components/shared/searchListsItem/AirlinesLogoName';
import OriginDepartureInfo from '@/components/shared/searchListsItem/OriginDepartureInfo';
import TransitInfo from '@/components/shared/searchListsItem/TransitInfo';
import DestinationArrivalInfo from '@/components/shared/searchListsItem/DestinationArrivalInfo';

const animals = [
  { key: 1, label: '1' },
  { key: 2, label: '2' },
  { key: 3, label: '3' },
  { key: 4, label: '4' },
  { key: 5, label: '5' },
  { key: 6, label: '6' },
  { key: 7, label: '7' },
  { key: 8, label: '8' },
  { key: 9, label: '9' },
];

export default function GroupSearchListsItem({ userInfo, item }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [showDetails, setShowDetails] = useState(false);
  const [paxNo, setPaxNo] = useState(1);
  const [loading, setLoading] = useState(false);

  const userRole = userInfo?.role;

  const dispatch = useAppDispatch();
  const router = useRouter();
  const searchParams = useSearchParams();
  const searchId = searchParams.get('sid');

  const ifIsAuthenticated = useAppSelector((state) => state.userData.isLogged);

  const airWaysName = Object.values(item?.airline).filter(
    (airData: any) =>
      item?.routes[0]?.FlightDetails[0]?.companyId === airData?.code ||
      item?.routes[0]?.FlightDetails[1]?.companyId === airData?.code ||
      item?.routes[0]?.FlightDetails[2]?.companyId === airData?.code ||
      item?.routes[0]?.FlightDetails[3]?.companyId === airData?.code ||
      item?.routes[1]?.FlightDetails[0]?.companyId === airData?.code ||
      item?.routes[2]?.FlightDetails[0]?.companyId === airData?.code ||
      item?.routes[3]?.FlightDetails[0]?.companyId === airData?.code,
  );

  const handleSelectionChange = (e) => {
    setPaxNo(e.target.value);
  };

  const handleBook = useCallback(async () => {
    if (!ifIsAuthenticated) {
      router.replace('/login');
    }

    try {
      setLoading(true);
      router.replace(
        `book-group?sid=${searchId}&bid=${item?.uid}&pax=${paxNo}`,
      );
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [paxNo, searchId, item, dispatch, router]);

  return (
    <div className="h-fit overflow-hidden rounded border p-3 hover:border-zinc-300 sm:p-4">
      <div className="flex size-full flex-col justify-between gap-3 3xl:flex-row 3xl:gap-5">
        {/* ---> Flight Info Part <--- */}
        <div className="flex w-full flex-col">
          {/* ---> First flight <--- */}
          <div className="flex w-full flex-col justify-between gap-1 3xl:flex-row">
            {/* ---> Logo & Name Part <--- */}
            <div className="flex flex-row justify-between gap-3 3xl:flex-col 3xl:justify-evenly">
              <AirlinesLogoName
                allData={item}
                item={item}
                route={item?.routes[0]}
                className="3xl:w-44"
              />

              {/* Hide in desktop devices */}
              <GroupPricingInfo
                item={item}
                userRole={userRole}
                className="3xl:hidden"
              />
            </div>

            {/* ---> Depart & Arrive part <--- */}
            <div className="flex flex-1 items-center justify-between gap-1 rounded bg-slate-100 p-2 lg:gap-5">
              <OriginDepartureInfo
                allData={item}
                route={item?.routes[0]}
                split={true}
                className="flex-1"
              />
              <TransitInfo route={item?.routes[0]} />

              <DestinationArrivalInfo
                allData={item}
                route={item?.routes[0]}
                split={true}
                className="flex-1"
              />
            </div>
          </div>

          {/* ---> Second flight <--- */}
          {item?.routes?.length > 1 &&
            item?.routes?.slice(1).map((route: any, index: any) => (
              <div
                key={index}
                className="flex w-full flex-col justify-between gap-1 3xl:flex-row"
              >
                {/* ---> Logo & Name Part <--- */}
                <AirlinesLogoName
                  allData={item}
                  item={item}
                  route={route}
                  className="hidden 3xl:flex 3xl:w-44"
                />

                {/* ---> Depart & Arrive Part <--- */}
                <div className="flex-1">
                  <h3 className="my-1 text-center text-[10px] font-semibold 3xl:text-xs">
                    Return
                  </h3>
                  <div className="flex items-center justify-between gap-1 rounded bg-slate-100 p-2 lg:gap-5">
                    <OriginDepartureInfo
                      allData={item}
                      route={route}
                      split={true}
                      className="flex-1"
                    />
                    <TransitInfo route={route} />
                    <DestinationArrivalInfo
                      allData={item}
                      route={route}
                      split={true}
                      className="flex-1"
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* ---> Price & Booking Part <--- */}
        <div className="flex items-center justify-between md:gap-5 3xl:min-w-72 3xl:py-0">
          <button
            onClick={() => setShowDetails(!showDetails)}
            className="flex select-none items-center gap-1 rounded-[5px] bg-red-500 px-[6px] py-[2px] text-xs font-medium text-white 3xl:mt-1 3xl:hidden"
          >
            <span>Details</span>
            <span
              className={`text-base ${
                showDetails ? 'rotate-180' : 'rotate-0'
              } transition-all duration-500 ease-in-out`}
            >
              <BiChevronDown />
            </span>
            {userRole === 'SUDO' ||
            userRole === 'ADMIN' ||
            userRole === 'MOD' ? (
              <span className="text-xs">{item?.tracker?.slice(0, 1)}</span>
            ) : (
              <></>
            )}
          </button>

          <div className="flex flex-row-reverse items-center gap-1 font-medium text-gray-600 3xl:flex-col 3xl:items-start">
            <div className="flex items-center whitespace-nowrap text-[10px] md:text-xs">
              <MdAirlineSeatReclineNormal />:
              <h3>
                <span className="ml-1">
                  {item?.routes?.[0]?.FlightDetails[0]?.rbd}-
                  {item?.routes?.[0]?.FlightDetails[0]?.avlStatus}
                </span>
                <span className="mx-[2px]">-</span>
                <span className="capitalize">
                  {item?.routes?.[0]?.FlightDetails[0]?.cabin?.slice(0, 3)}
                </span>
              </h3>
            </div>
            <h3 className="text-[10px] md:text-xs 3xl:hidden">|</h3>
            <h3 className="flex items-center text-[10px] md:text-xs">
              <FaPersonWalkingLuggage /> : {item?.routes?.[0]?.adultBaggage}
            </h3>
            <button
              onClick={() => setShowDetails(!showDetails)}
              className="hidden select-none items-center gap-1 rounded-[5px] bg-red-500 px-1 text-xs font-medium text-white 3xl:mt-1 3xl:flex"
            >
              <span>Details</span>
              <span
                className={`text-base ${
                  showDetails ? 'rotate-180' : 'rotate-0'
                } transition-all duration-500 ease-in-out`}
              >
                <BiChevronDown />
              </span>
              {userRole === 'SUDO' ||
              userRole === 'ADMIN' ||
              userRole === 'MOD' ? (
                <span className="text-xs">{item?.tracker?.slice(0, 1)}</span>
              ) : (
                <></>
              )}
            </button>
          </div>

          {/* Hide in tablet & mobile devices */}
          <GroupPricingInfo
            item={item}
            userRole={userRole}
            className="hidden 3xl:block"
          />

          {/* ---> booking button <--- */}
          {loading ? (
            <Button className="h-6 w-14 rounded-[7px] md:h-7 md:w-16 3xl:w-20">
              <Loader2 className="h-4 animate-spin" />
            </Button>
          ) : (
            <Button
              // onClick={handleBook}
              onPress={onOpen}
              className="h-6 w-14 rounded-[7px] text-[13px] md:h-7 md:w-16 3xl:w-20"
            >
              Book
            </Button>
          )}
        </div>
      </div>
      {/* ---> Modal <--- */}
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Select Number of Pax
              </ModalHeader>
              <ModalBody>
                <Select
                  isRequired
                  label="No of Pax"
                  placeholder="Select a Number"
                  defaultSelectedKeys={['1']}
                  onChange={handleSelectionChange}
                  className="max-w-xs"
                >
                  {animals.map((item) => (
                    <SelectItem key={item.key}>{item.label}</SelectItem>
                  ))}
                </Select>
                <p className="text-small text-default-500">Selected: {paxNo}</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={handleBook}>
                  Book
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      {/* ---> Flight Details <--- */}
      {showDetails && (
        <Tabs defaultValue="flight" className="mt-3 w-full border-t pt-4">
          <TabsList className="mb-2 grid w-full grid-cols-4 bg-slate-200 p-1 text-slate-900">
            <TabsTrigger value="flight">Details</TabsTrigger>
            <TabsTrigger value="baggage">Baggage</TabsTrigger>
            <TabsTrigger value="fareInfo">Fare Info</TabsTrigger>
            <TabsTrigger value="fareRules">Fare Rules</TabsTrigger>
          </TabsList>
          <TabsContent value="flight">
            <FlightTab allData={item} item={item} airWaysName={airWaysName} />
          </TabsContent>
          <TabsContent value="baggage">
            <BaggageTab item={item} />
          </TabsContent>
          <TabsContent value="fareInfo">
            <FareInfoTab item={item} />
          </TabsContent>
          <TabsContent value="fareRules">
            <p className="items-center justify-center">
              Group Fare has no Flight Rules !
            </p>
          </TabsContent>
        </Tabs>
      )}
    </div>
  );
}
