import { cn } from '@/utils/utils';

interface CustomTableProps {
  className?: string;
  children: React.ReactNode;
}
const CustomTable: React.FC<CustomTableProps> = ({
  className = '',
  children,
}) => {
  return (
    <div
      className={cn(
        'overflow-x-auto rounded-md border-[1.5px] border-gray-200',
        className,
      )}
    >
      <table className="min-w-full">{children}</table>
    </div>
  );
};

export default CustomTable;
