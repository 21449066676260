'use client';
import { Key } from 'react';
import GroupSearchListsItem from './GroupSearchListsItem';

function GroupShowAll({ groupItem, userInfo }) {
  return groupItem?.length > 0 ? (
    <div className="min-h-56">
      <h1 className="mb-4 text-center text-xl font-medium leading-none">
        Group Fares
      </h1>
      <div className="space-y-3 sm:space-y-5">
        {groupItem?.map((data: any, index: Key) => (
          <div key={index}>
            <GroupSearchListsItem userInfo={userInfo} item={data} />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <h1 className="flex min-h-28 items-center justify-center text-center text-xl font-medium leading-none">
      No Group Fares Available!
    </h1>
  );
}

export default GroupShowAll;
