'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { memberOf, weAccept } from '@/utils/footerInfo';
import { FaFacebook, FaLinkedin, FaMapMarkerAlt } from '@Icons/fa';
import { FaXTwitter } from '@Icons/fa6';
import { MdEmail } from '@Icons/md';
import { BsFillPhoneFill } from '@Icons/bs';
import { useRouter } from 'next/navigation';
import { cn } from '@/utils/utils';

const Footer = ({ className = '' }) => {
  const router = useRouter();

  const renderMemberOfSection = () => (
    <div>
      <h3 className="text-base font-semibold text-gray-700">Member Of</h3>

      <div className="mt-3 flex items-center gap-2 text-sm">
        {memberOf.map((data) => (
          <Image
            src={data.img}
            alt={data.img}
            height={40}
            width={40}
            key={data.id}
            className="rounded-md object-contain"
          />
        ))}
      </div>
    </div>
  );

  const renderWeAcceptSection = () => (
    <div>
      <h3 className={cn('text-base font-semibold text-gray-700', className)}>
        We Accept
      </h3>

      <div className="mt-3 flex flex-wrap items-center gap-2 text-sm">
        {weAccept.map((data) => (
          <Image
            src={data.img}
            alt={data.img}
            height={40}
            width={40}
            key={data.id}
            className="rounded-md object-contain"
          />
        ))}
      </div>
    </div>
  );

  return (
    <footer className="bg-gray-100 pt-10 text-sm text-gray-600">
      <div className="custom-container">
        {/*  */}
        <div className="grid grid-cols-2 gap-10 border-b border-gray-200 pb-5 2xl:grid-cols-4">
          <div className="col-span-2 space-y-4 md:col-span-1">
            <div
              onClick={() => {
                router.replace('/');
              }}
              className="flex cursor-pointer items-center"
            >
              <Image
                src="/tmlogo.png"
                alt="TicketMet Logo"
                width={36}
                height={36}
                className="size-7 rounded-full object-cover 2xl:size-8"
              />

              <h1 className="text-xl font-bold tracking-tighter text-gray-700 2xl:text-2xl">
                Ticketmet
              </h1>
            </div>

            <div className="">
              <Link href="/" className="text-primary_color">
                TicketMet
              </Link>{' '}
              is your all-in-one travel partner, offering seamless booking
              solutions for flights, hotels, tours, and visas. Whether
              you&apos;re planning a business trip or a dream vacation, we
              provide tailored services to make your journey stress-free.
            </div>
          </div>

          <div>
            <h3 className="text-base font-semibold text-gray-700">Services</h3>

            <div className="mt-3 flex flex-col gap-2">
              <Link
                href="/"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Flight
              </Link>
              <Link
                href="/"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Hotel
              </Link>
              <Link
                href="/"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Tour
              </Link>
              <Link
                href="/"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Visa
              </Link>
            </div>
          </div>
          <div className="">
            <h3 className="text-base font-semibold text-gray-700">Company</h3>

            <div className="mt-3 flex flex-col gap-2">
              <Link
                href="/about-us"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                About Us
              </Link>
              <Link
                href="/why-ticketmet"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Why Ticketmet
              </Link>
              <Link
                href="/privacy-policy"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Privacy Policy
              </Link>
              <Link
                href="/terms-&-conditions"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
          <div className="">
            <h3 className="text-base font-semibold text-gray-700">Explore</h3>

            <div className="mt-3 flex flex-col gap-2">
              <Link
                href="/travel-guide"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Travel Guide
              </Link>
              <Link
                href="/"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                FAQ
              </Link>
              <Link
                href="/"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Blog
              </Link>
              <Link
                href="/investors"
                className="w-fit underline-offset-4 hover:text-primary_color hover:underline"
              >
                Investors
              </Link>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="grid grid-cols-1 gap-5 border-b border-gray-200 py-5 sm:grid-cols-2 2xl:grid-cols-4">
          <div>
            <h3 className="text-base font-semibold text-gray-700">
              Contact Us
            </h3>

            <div className="mt-3 flex flex-col gap-2">
              <p className="flex items-center gap-3">
                <MdEmail className="text-2xl text-tertiary_color" />

                <span className="flex flex-col text-[13px] text-gray-700">
                  <span className="font-medium hover:text-primary_color">
                    bhuiyanitt@gmail.com
                  </span>
                  <span className="font-medium hover:text-primary_color">
                    support@ticketmet.com
                  </span>
                </span>
              </p>
              <p className="flex items-center gap-3">
                <BsFillPhoneFill className="text-2xl text-tertiary_color" />

                <span className="flex flex-col text-[13px] text-gray-700">
                  <span className="font-medium hover:text-primary_color">
                    +8801727706200
                  </span>
                  <span className="font-medium hover:text-primary_color">
                    +8801710007666
                  </span>
                </span>
              </p>
            </div>
          </div>

          <div>
            <h3 className="text-base font-semibold text-gray-700">
              Dhaka Office
            </h3>

            <div className="my-3 flex h-14 flex-col text-sm">
              <p>769 West Shewrapara,</p>
              <p>Mirpur Dhaka - 1216,</p>
              <p>Bangladesh</p>
            </div>

            <Link
              href="/"
              className="flex w-fit items-center gap-1 font-medium text-gray-700 duration-250 ease-in-out active:scale-95"
            >
              <FaMapMarkerAlt className="text-xl text-tertiary_color" />
              <span className="hover:text-primary_color">View Map</span>
            </Link>
          </div>
        </div>

        {/*  */}
        <div className="flex flex-wrap justify-between gap-5 border-b border-gray-200 py-5">
          <div>
            <h3 className="text-base font-semibold text-gray-700">Follow Us</h3>

            <div className="mt-3 flex items-center gap-4 text-xl text-gray-400">
              <Link
                href="/"
                className="duration-500 ease-in-out hover:text-gray-700 active:scale-95"
              >
                <FaFacebook />
              </Link>
              <Link
                href="/"
                className="duration-500 ease-in-out hover:text-gray-700 active:scale-95"
              >
                <FaLinkedin />
              </Link>
              <Link
                href="/"
                className="duration-500 ease-in-out hover:text-gray-700 active:scale-95"
              >
                <FaXTwitter />
              </Link>
            </div>
          </div>{' '}
          {renderMemberOfSection()} {renderWeAcceptSection()}{' '}
        </div>

        <div className="py-8 text-center">
          <p className="text-sm">
            &copy; 2018-{new Date().getFullYear()} All Rights Reserved By
            Ticketmet
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
