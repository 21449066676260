export const topAirlinesData = [
  {
    id: 1,
    name: 'Biman Bangladesh Airlines',
    image: '/airline/biman_airline.png',
  },
  {
    id: 2,
    name: 'US-Bangla Airlines',
    image: '/airline/us-bangla_airline.png',
  },
  {
    id: 3,
    name: 'Novoair',
    image: '/airline/novoair_airline.png',
  },
  {
    id: 4,
    name: 'Air Astra',
    image: '/airline/airastra_airline.png',
  },
  {
    id: 5,
    name: 'Qatar Airways',
    image: '/airline/qatar_airline.png',
  },
  {
    id: 6,
    name: 'Emirates',
    image: '/airline/emirates_airline.png',
  },
  {
    id: 7,
    name: 'Singapore Airlines',
    image: '/airline/singapore_airline.png',
  },
  {
    id: 8,
    name: 'Thai Airways',
    image: '/airline/thai_airline.png',
  },
  {
    id: 9,
    name: 'Turkish Airlines',
    image: '/airline/turkish_airline.png',
  },
  {
    id: 10,
    name: 'Saudia',
    image: '/airline/saudia_airline.png',
  },
  {
    id: 11,
    name: 'IndiGo',
    image: '/airline/indigo_airline.png',
  },
  {
    id: 12,
    name: 'Malaysia Airlines',
    image: '/airline/malaysia_airline.png',
  },
  {
    id: 13,
    name: 'SriLankan Airlines',
    image: '/airline/srilankan_airline.png',
  },
  {
    id: 14,
    name: 'Vistara',
    image: '/airline/vistara_airline.png',
  },
  {
    id: 15,
    name: 'Air India',
    image: '/airline/air-india_airline.png',
  },
  {
    id: 16,
    name: 'Kuwait Airways',
    image: '/airline/kuwait_airline.png',
  },
];
