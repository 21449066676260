import { useState } from 'react';
import { FiClock } from '@/Icons/fi';
import { calculateDuration } from '@/utils/calculateDuration';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';

export default function TransitInfo({ route }: any) {
  const [isFlightDetailsOpen, setIsFlightDetailsOpen] = useState(false);
  return (
    <div className="flex w-full flex-col items-center justify-center gap-1">
      <h3 className="flex items-center gap-1 text-[10px] font-medium text-gray-600 lg:text-xs">
        <FiClock />
        {route?.totalTravelTime}
      </h3>

      {route.FlightDetails.length > 1 && (
        <Popover
          open={isFlightDetailsOpen}
          onOpenChange={setIsFlightDetailsOpen}
        >
          <PopoverTrigger className="flex cursor-pointer items-center rounded-full bg-slate-200 px-2 py-[2px] text-[9px] font-medium text-gray-600 lg:text-[11px]">
            <>
              <span
                className={`text-red-500 ${
                  route?.FlightDetails?.length < 2 && 'hidden'
                }`}
              >
                {route?.FlightDetails?.length > 1 &&
                  route?.FlightDetails[0]?.destination}
              </span>
              <span
                className={`mx-1 ${
                  route?.FlightDetails?.length < 3 && 'hidden'
                }`}
              >
                -
              </span>
              <span
                className={`text-green-500 ${
                  route?.FlightDetails?.length < 2 && 'hidden'
                }`}
              >
                {route?.FlightDetails?.length > 2 &&
                  route?.FlightDetails[1]?.destination}
              </span>
            </>
          </PopoverTrigger>
          <PopoverContent className="w-full rounded-lg border-none p-2 text-center font-medium shadow-[0_0_5px_rgba(0,0,0,0.1)]">
            <h3 className="text-center text-[10px] text-gray-600 lg:text-xs">
              Plane Change at
            </h3>
            <div className="flex items-center justify-center gap-2">
              {route?.FlightDetails?.slice(0, -1).map(
                (flightDetail: any, index: any) => (
                  <h3
                    key={index}
                    className="flex items-center gap-1 text-[8px] lg:text-[10px]"
                  >
                    {index >= 1 ? (
                      <span className="mr-1 text-xs font-normal">|</span>
                    ) : (
                      <></>
                    )}
                    <span>{flightDetail.destination}</span>-
                    <span
                      className={index > 0 ? 'text-green-500' : 'text-red-500'}
                    >
                      {calculateDuration(
                        flightDetail.arrival,
                        route?.FlightDetails[index + 1]?.departure,
                      )}
                    </span>
                  </h3>
                ),
              )}
            </div>
          </PopoverContent>
        </Popover>
      )}

      <div className="flex items-center">
        {route?.FlightDetails?.slice(0, -1).map(
          (flightDetail: any, index: any) => (
            <h3 key={index} className="text-[10px] font-semibold">
              {index >= 1 ? (
                <span className="mx-1 text-xs font-normal">-</span>
              ) : (
                <></>
              )}
              <span
                className={`whitespace-nowrap ${
                  index % 2 === 0 ? 'text-red-500' : 'text-green-500'
                }`}
              >
                {calculateDuration(
                  flightDetail.arrival,
                  route?.FlightDetails[index + 1]?.departure,
                )}
              </span>
            </h3>
          ),
        )}
      </div>
    </div>
  );
}
