import Image from 'next/image';
import React from 'react';

export default function AirlinesLogoName({
  allData,
  item,
  route,
  className,
}: any) {
  const companyIds = new Set(); // Create a set to store unique companyIds

  // Loop through the FlightDetails and add companyIds to the set
  route?.FlightDetails.forEach((flight) => {
    companyIds.add(flight.companyId);
  });

  const uniqueAirlines = Array.from(companyIds); // Convert the set to an array to get the unique airlines

  let airWaysNameAndLogo: { logo?: any; name?: any };
  const logoComponents = uniqueAirlines.map((companyId: any, index) => {
    if (item?.airline && item?.airline[companyId]) {
      airWaysNameAndLogo = item?.airline[companyId];
    } else if (allData?.airline && allData?.airline[companyId]) {
      airWaysNameAndLogo = allData?.airline[companyId];
    } else {
      // console.error('Airline data not found for companyId:', companyId);
      airWaysNameAndLogo = {}; // Set to an empty object or some default value
    }

    return (
      <div
        key={companyId}
        className={`rounded-full border ${
          index > 0 ? '-ml-7 3xl:-mt-8 3xl:ml-0' : ''
        } overflow-hidden`}
      >
        <Image
          key={companyId}
          src={
            airWaysNameAndLogo?.logo?.includes('https')
              ? airWaysNameAndLogo?.logo
              : `https://cdn.ticketmet.com/aircontrols/${airWaysNameAndLogo?.logo}`
          }
          alt={companyId}
          width={40}
          height={40}
          className="size-8 rounded-full object-contain md:size-10"
        />
      </div>
    );
  });

  const firstCompanyId = route.FlightDetails[0]?.companyId;
  let firstAirline: { name?: any };

  if (item?.airline && item?.airline[firstCompanyId]) {
    firstAirline = item?.airline[firstCompanyId];
  } else if (allData?.airline && allData?.airline[firstCompanyId]) {
    firstAirline = allData?.airline[firstCompanyId];
  } else {
    // Handle the case where the airline data is not found
    // console.error('Airline data not found for firstCompanyId:', firstCompanyId);
    // You may want to set a default value or take other appropriate actions
    firstAirline = {}; // Set to an empty object or some default value
  }

  return (
    <div className={`flex items-center gap-2 lg:gap-3 ${className}`}>
      <div className="flex items-center 3xl:flex-col">{logoComponents}</div>

      <div className="">
        {' '}
        <h3
          className={`text-xs font-semibold lg:text-sm ${
            firstAirline?.name ? 'text-gray-600' : 'text-red-600'
          }`}
        >
          {firstAirline?.name ? (
            firstAirline?.name
          ) : (
            <span className="text-xs">Name Not Found</span>
          )}
        </h3>
        <h3 className="text-[9px] font-medium text-gray-500 lg:text-[11px]">
          {route?.FlightDetails[0]?.companyId}
          {route?.FlightDetails[0]?.flightNumber}

          {route?.FlightDetails[0]?.equipmentType && (
            <>
              <span className="mx-1">-</span>
              {route?.FlightDetails[0]?.equipmentType}
            </>
          )}
        </h3>
        <h3
          className={`text-[9px] font-medium lg:text-[11px] ${
            item?.isRefundable ? 'text-green-600' : 'text-red-600'
          }`}
        >
          {item?.isRefundable ? 'PAR. REFUND' : 'NON. REFUND'}
        </h3>
      </div>
    </div>
  );
}
