export const footerInfo = [
  {
    id: 'fi1',
    category: 'Services',
    items: ['Flights', 'Hotels', 'Visa', 'Holidays', 'Recharge'],
  },
  {
    id: 'fi2',
    category: 'Terms',
    items: [
      'Privacy Policy',
      'Terms & Conditions',
      'Purchase & Sale',
      'Suppliers',
    ],
  },
  {
    id: 'fi3',
    category: 'Company',
    items: ['About Us', 'Management', 'Blog', 'Investors', 'Contact Us'],
  },
];

export const memberOf = [
  {
    id: 'm1',
    img: '/iata.png',
  },
  {
    id: 'm2',
    img: '/civil.png',
  },
];

export const weAccept = [
  {
    id: 'amexcard',
    img: '/amex.png',
  },
  {
    id: 'mastercard',
    img: '/master-card.png',
  },
  {
    id: 'visa',
    img: '/visa.png',
  },
  {
    id: 'unionpay',
    img: '/unionpay.png',
  },
  {
    id: 'nagad',
    img: '/nagad.png',
  },
  {
    id: 'bkash',
    img: '/bkash.png',
  },
];

export const partners = [
  {
    id: 'p1',
    img: '/trip-advisor.png',
  },
  {
    id: 'p2',
    img: '/trip-advisor.png',
  },
  {
    id: 'p3',
    img: '/trip-advisor.png',
  },
];
