import React from 'react';
import { cn } from '@/utils/utils';

interface CustomTableRowProps {
  className?: string;
  children: React.ReactNode;
  colSpan?: number; // Add colSpan to manage full-width rows
}
const CustomTRow: React.FC<CustomTableRowProps> = ({
  className = '',
  children,
  colSpan,
}) => {
  return (
    <tr className={cn('min-w-0 bg-white hover:bg-black/5', className)}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return (
            <td
              key={index}
              className="min-w-0 px-3 text-[13px] text-gray-500"
              colSpan={colSpan}
            >
              {child}
            </td>
          );
        }
        return null;
      })}
    </tr>
  );
};

export default CustomTRow;
