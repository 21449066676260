export function formatDate(input: any): string {
  const [datePart, timePart] = input.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute] = timePart.split(':').map(Number);

  const date = new Date(Date.UTC(year, month - 1, day, hour, minute));

  const utcString = date.toUTCString();

  const formattedParts = utcString.split(' ').slice(0, 4);
  formattedParts[3] = formattedParts[3].slice(-2);

  formattedParts[2] = formattedParts[2] + ',';
  return formattedParts.join(' ');
}

export function formatTime(input: any): string {
  const timePart = input.split('T')[1];
  const [hour, minute] = timePart.split(':').map(Number);

  const date = new Date(Date.UTC(0, 0, 0, hour, minute));

  const utcString = date.toUTCString();

  const formattedTime = utcString.split(' ')[4].slice(0, 5);
  return formattedTime;
}

export function formateListDepositsDate(input: any) {
  const date = new Date(input);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDate = `${date.getUTCDate()} ${
    months[date.getUTCMonth()]
  }, ${date.getUTCFullYear().toString().substr(-2)}`;

  return formattedDate;
}
