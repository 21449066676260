import React from 'react';
import { cn } from '@/utils/utils';

interface CustomTableHeadProps {
  className?: string;
  children: React.ReactNode;
}

interface ClassNamesInterface {
  className?: any;
}

const CustomTHead: React.FC<CustomTableHeadProps> = ({
  className = '',
  children,
}) => {
  return (
    <thead className={cn('bg-black/5', className)}>
      <tr>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const { props } = child;
            const { className } = props as ClassNamesInterface;
            return (
              <th
                className={cn(
                  'border-b border-gray-300 px-3 text-left text-xs font-medium uppercase tracking-wider text-gray-600',
                  className,
                )}
              >
                {child}
              </th>
            );
          }
          return null;
        })}
      </tr>
    </thead>
  );
};

export default CustomTHead;
