import { cn } from '@/utils/utils';

interface HeadlineProps {
  title: string;
  desc?: string;
  className?: string;
}

export default function Headline({
  title,
  desc,
  className = '',
}: HeadlineProps) {
  return (
    <div className={cn('space-y-3 text-center', className)}>
      <h2 className="text-2xl font-bold md:text-3xl">{title}</h2>
      <p className="mx-auto max-w-[600px] text-sm text-gray-500 md:text-base 2xl:max-w-[700px]">
        {desc}
      </p>
    </div>
  );
}
