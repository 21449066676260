export default function GroupPricingInfo({ item, className }: any) {
  const { currency, totalPayable, charge } = item;

  return (
    <div className={className}>
      <div className="flex items-center gap-1 text-sm font-bold 3xl:text-base">
        <h4>{currency}</h4>
        {/* <h3>{new Intl.NumberFormat('en-IN').format(totalPayable+charge)}</h3> */}
        {/* <h3>{totalPayable + charge}</h3> */}
        <h3>{formatIndianNumber(totalPayable + charge)}</h3>
      </div>
    </div>
  );
}

export function formatIndianNumber(number) {
  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = number.toString().split('.');

  // Reverse the integer part to make it easier to group digits
  const reversedInteger = integerPart.split('').reverse().join('');

  // Add commas
  let formattedInteger = '';
  for (let i = 0; i < reversedInteger.length; i++) {
    if (i === 3) {
      formattedInteger += ',';
    } else if (i > 3 && (i - 3) % 2 === 0) {
      formattedInteger += ',';
    }
    formattedInteger += reversedInteger[i];
  }

  // Reverse back the formatted integer part
  formattedInteger = formattedInteger.split('').reverse().join('');

  // Combine integer and decimal parts
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}
