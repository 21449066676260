import React from 'react';
import { cn } from '@/utils/utils';

interface CustomTableBodyProps {
  className?: string;
  children: React.ReactNode;
}
const CustomTBody: React.FC<CustomTableBodyProps> = ({
  className = '',
  children,
}) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <tbody className={cn('', className)}>
      {childrenArray.map((child, index) => (
        <React.Fragment key={index}>
          {React.cloneElement(child as React.ReactElement<any>, {
            className: `${
              index !== childrenArray.length - 1 && 'border-b border-gray-200'
            }`,
          })}
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default CustomTBody;
