import { formatDate, formatTime } from '@/utils/formateDate';

export default function OriginDepartureInfo({
  allData,
  itemData,
  route,
  className,
}: any) {
  const airportNames = allData ? allData?.airport : itemData?.airport;
  const firstFlightDetail =
    route?.FlightDetails && route?.FlightDetails.length > 0
      ? route?.FlightDetails[0]
      : null;

  const originFromFlightDetail = firstFlightDetail?.origin;
  const originFromRoute = route?.origin;

  const airportOriginCity = airportNames
    ? airportNames[originFromFlightDetail || originFromRoute]
    : null;

  return (
    <div className={`font-medium text-gray-600 ${className}`}>
      {/* ---> Depart Time <-- */}
      <h3 className="time-of-departure font-bold lg:text-lg">
        {formatTime(route?.FlightDetails[0]?.departure) ||
          formatTime(route?.departure)}
      </h3>

      {/* ---> Depart Data <--- */}
      <h3 className="date-of-departure whitespace-nowrap text-[10px] md:text-xs">
        {formatDate(route?.FlightDetails[0]?.departure) ||
          formatDate(route?.departure)}
      </h3>

      {/* ---> Depart Origin <--- */}
      <h3 className="mt-1 whitespace-nowrap text-xs">
        {airportOriginCity?.name.length > 20 ? (
          <>
            <span className="hidden md:block">
              {airportOriginCity?.city} ({route?.FlightDetails[0]?.origin}) -{' '}
              {airportOriginCity?.name.slice(0, 20)}..
            </span>
            <span className="md:hidden">
              {airportOriginCity?.city} ({route?.FlightDetails[0]?.origin})
            </span>
          </>
        ) : (
          <>
            <span className="hidden md:block">
              {airportOriginCity?.city} ({route?.FlightDetails[0]?.origin}) -{' '}
              {airportOriginCity?.name}
            </span>
            <span className="md:hidden">
              {airportOriginCity?.city} ({route?.FlightDetails[0]?.origin})
            </span>
          </>
        )}
      </h3>
    </div>
  );
}
