'use client';

import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from '@/store/hooks';
import useSecureFetcher from '@/hooks/useSecureFetcher';
import { FaHistory } from '@/Icons/fa';
import { SlArrowLeft, SlArrowRight } from '@/Icons/sl';
import Headline from './Headline';
import '@/styles/banner/banner.css';
import {
  bannerList,
  categoriesList,
  flightsList,
} from '@/constants/searchItems';
import OneWaySearchTab from '@/app/(public-pages)/(flight)/flight/components/oneway/OneWaySearchTab';
import ReturnSearchTab from '@/app/(public-pages)/(flight)/flight/components/return/ReturnSearchTab';
import MulticitySearchTab from '@/app/(public-pages)/(flight)/flight/components/multicity/MulticitySearchTab';
import SearchHistoryTab from '@/app/(public-pages)/(flight)/flight/components/SearchHistoryTab';
import GroupShowAll from '@/app/(public-pages)/book-group/components/GroupShowAll';

export default function HeroBanner() {
  // redux states
  const searchType = useAppSelector((state) => state.searchType);
  const userInfo = useAppSelector((state) => state.userData.user);
  const allAirportsData = useAppSelector(
    (state) => state.airportsData.airports as any,
  );

  // use states
  const [searchingCategory, setSearchingCategory] = useState('flight');
  const [searchingType, setSearchingType] = useState('oneway');
  const [groupItem, setGroupItem] = useState();
  const [searchHistoryReq, setSearchHistoryReq] = useState<any>([]);
  const [isSearchHistoryOpen, setIsSearchHistoryOpen] = useState(false);
  const [extraSlider, setExtraSlider] = useState<any>([]);
  const sliders = [...bannerList, ...extraSlider];
  //   constants
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  useEffect(() => {
    if (userInfo !== null) {
      const GetAllHistory = async () => {
        const response = await useSecureFetcher(`v1/searchReq/search_req`, {});
        if (response.status !== 'error') {
          setSearchHistoryReq(response.req);
        }
      };
      GetAllHistory();
    }
    const GetExtraSliders = async () => {
      const response = await useSecureFetcher(
        `v1/slider/GetAllActiveSliders`,
        {},
      );
      if (response.status !== 'error') {
        setExtraSlider(response);
      }
    };
    GetExtraSliders();
  }, [searchType.type]); // Add searchType.type as a dependency

  useEffect(() => {
    const GetAllGroup = async () => {
      const response = await useSecureFetcher('v1/groupFare/allShow', {}); // fetching user data from hook
      if (response.status !== 'error') {
        setGroupItem(response);
      }
    };
    GetAllGroup();
  }, []);

  return (
    <div className="relative min-h-screen bg-white pb-10">
      <div
        className="absolute inset-0 w-full bg-black"
        style={{
          height: '500px',
          clipPath: "path('M0,400 Q960,500 1920,400 L1920,0 L0,0 Z')",
        }}
      >
        <Image
          alt="banner"
          src="/hero-bg.jpg"
          width={10000}
          height={500}
          className="z-0 size-full object-cover opacity-65"
        />

        {/* Vertical Lines (with Top to Bottom and Bottom to Top Animation) */}
        <div className="absolute inset-0 z-50">
          <div className="animate-top-bottom absolute left-[20%] h-full w-px bg-gradient-to-b from-white/25 to-white/0 delay-0"></div>
          <div className="animate-top-bottom delay-600 absolute left-[40%] h-full w-px bg-gradient-to-b from-white/25 to-white/0"></div>
          <div className="animate-top-bottom absolute left-[60%] h-full w-px bg-gradient-to-b from-white/25 to-white/0 delay-200"></div>
          <div className="animate-top-bottom delay-400 absolute left-[80%] h-full w-px bg-gradient-to-b from-white/25 to-white/0"></div>
        </div>

        {/* Horizontal Lines (with Left to Right and Right to Left Animation) */}
        <div className="absolute inset-0 z-50 w-full">
          <div className="animate-left-right absolute top-1/4 h-px w-full bg-gradient-to-r from-white/25 to-white/0 delay-100"></div>
          <div className="animate-left-right absolute top-1/2 h-px w-full bg-gradient-to-r from-white/25 to-white/0 delay-500"></div>
          <div className="animate-left-right absolute top-3/4 h-px w-full bg-gradient-to-r from-white/25 to-white/0 delay-300"></div>
        </div>
      </div>

      <div className="custom-container relative z-10 bg-transparent pt-20 md:pt-24 2xl:pt-28">
        <div className="mb-10 flex flex-col items-center justify-center gap-1 text-white md:mb-12 lg:gap-3 2xl:items-start">
          <h1 className="text-2xl font-medium lg:text-4xl">
            Welcome to{' '}
            <span
              className="bg-clip-text font-extrabold text-transparent"
              style={{
                backgroundImage:
                  'linear-gradient(160deg, #3333FF 38%, #FB8402 38%, #FB8402 62%, #F80100 62%)',
              }}
            >
              Ticketmet!
            </span>
          </h1>
          <p className="text-sm lg:text-base lg:font-medium">
            Find Your Best Flights, Hotels, Visa & Fare
          </p>
        </div>
        <div className="relative w-full rounded-xl bg-white pt-6 shadow-[0_16px_15px_-10px_rgba(0,0,0,0.1)] shadow-gray-200 md:pt-8">
          {/*  */}
          <div className="absolute left-1/2 top-0 mx-auto flex h-11 w-full -translate-x-1/2 -translate-y-1/2 items-center whitespace-nowrap rounded-lg bg-white p-1 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)] shadow-gray-200 md:h-[50px] md:w-[500px]">
            <div
              ref={prevButtonRef}
              className="flex size-7 h-full cursor-pointer select-none items-center justify-center rounded-md bg-primary_color/5 text-sm duration-250 ease-in-out hover:bg-primary_color/10 active:bg-primary_color/15 md:hidden"
            >
              <SlArrowLeft />
            </div>

            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                375: {
                  slidesPerView: 4,
                },
              }}
              navigation={{
                nextEl: nextButtonRef.current,
                prevEl: prevButtonRef.current,
              }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              className="size-full rounded-md"
            >
              {categoriesList.map((category) => (
                <SwiperSlide key={category.id} className="size-full">
                  <button
                    onClick={() => setSearchingCategory(category.code)}
                    className={`flex size-full items-center justify-center gap-2 rounded-md text-xs font-medium md:text-sm ${
                      searchingCategory === category.code
                        ? 'bg-primary_color text-white'
                        : 'hover:bg-primary_color/10'
                    }`}
                  >
                    {searchingCategory === category.code ? (
                      <>
                        {category?.activeIcon}
                        {category?.name}
                      </>
                    ) : (
                      <>
                        {category?.icon}
                        {category?.name}
                      </>
                    )}
                  </button>
                </SwiperSlide>
              ))}
            </Swiper>

            <div
              ref={nextButtonRef}
              className="flex h-full w-7 min-w-7 cursor-pointer select-none items-center justify-center rounded-md bg-primary_color/5 text-sm duration-250 ease-in-out hover:bg-primary_color/10 active:bg-primary_color/15 md:hidden"
            >
              <SlArrowRight />
            </div>
          </div>

          {searchingCategory === 'flight' ? (
            <div className="p-3 md:p-5">
              <div className="mb-5 flex items-center justify-between">
                <div className="flex w-full items-center gap-1 xs:gap-2">
                  {flightsList.map((flight, index) => (
                    <button
                      key={++index}
                      onClick={() => {
                        setSearchingType(flight?.code);
                        setIsSearchHistoryOpen(false);
                      }}
                      className={`h-6 whitespace-nowrap rounded-md border px-[10px] text-[11px] font-medium xs:h-7 xs:px-3 xs:text-xs sm:h-8 ${
                        searchingType === flight?.code
                          ? 'border-primary_color bg-primary_color text-white'
                          : 'hover:bg-primary_color/10'
                      }`}
                    >
                      <span className="hidden items-center justify-center gap-1 sm:flex">
                        {searchingType === flight?.code ? (
                          <>
                            {flight?.activeIcon}
                            {flight?.name}
                          </>
                        ) : (
                          <>
                            {flight?.icon}
                            {flight?.name}
                          </>
                        )}
                      </span>
                      <span className="flex items-center justify-center gap-1 sm:hidden">
                        {flight?.name}
                      </span>
                    </button>
                  ))}
                </div>
                {userInfo !== null ? (
                  <button
                    onClick={() => setIsSearchHistoryOpen(!isSearchHistoryOpen)}
                    className={`flex size-8 min-w-8 items-center justify-center gap-1 whitespace-nowrap rounded-md border text-xs font-medium md:w-32 md:min-w-32 ${
                      isSearchHistoryOpen
                        ? 'border-primary_color bg-primary_color text-white'
                        : 'hover:bg-primary_color/10'
                    }`}
                  >
                    <FaHistory />
                    <span className="hidden md:block"> Search History</span>
                  </button>
                ) : (
                  <></>
                )}
              </div>

              {searchingType === 'oneway' ? (
                <OneWaySearchTab />
              ) : searchingType === 'return' ? (
                <ReturnSearchTab />
              ) : searchingType === 'multicity' ? (
                <MulticitySearchTab />
              ) : (
                <></>
              )}

              {isSearchHistoryOpen ? (
                <SearchHistoryTab
                  allData={searchHistoryReq}
                  allAirportsData={allAirportsData}
                />
              ) : (
                <></>
              )}
            </div>
          ) : searchingCategory === 'hotel' ? (
            <div className="p-2 xs:p-3 md:p-5">
              <div className="mt-5 flex flex-col items-center justify-center">
                <Image
                  src={'/u1.svg'}
                  alt="Under Construction"
                  width={300}
                  height={300}
                  className="items-center justify-center"
                  sizes="(max-width: 639px) 100px, 300px"
                />
                <h1 className="text-bold mt-4 text-2xl">Under Development</h1>
              </div>
            </div>
          ) : searchingCategory === 'visa' ? (
            <div className="p-2 xs:p-3 md:p-5">
              <div className="mt-5 flex flex-col items-center justify-center">
                <Image
                  src={'/u1.svg'}
                  alt="Under Construction"
                  width={300}
                  height={300}
                  className="items-center justify-center"
                  sizes="(max-width: 639px) 100px, 300px"
                />
                <h1 className="text-bold mt-4 text-2xl">Under Development</h1>
              </div>
            </div>
          ) : searchingCategory === 'fare' ? (
            <div className="p-2 xs:p-3 md:p-5">
              <GroupShowAll groupItem={groupItem} userInfo={userInfo} />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="mt-16 space-y-5">
          <Headline title="Special Offers" className="text-start" />
          <Swiper
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper rounded-xl"
          >
            {sliders.map((banner, index) => (
              <SwiperSlide key={index}>
                <Image
                  src={banner.img}
                  alt={`${index}`}
                  width={500}
                  height={300}
                  className="mb-10 h-[250px] rounded-xl"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
