import { SetStateAction, useEffect, useRef, useState } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from '@/Icons/ri';
import { cn } from '@/utils/utils';

type TCustomDropdownProps = {
  options: { value: number | string; label: string }[];
  defaultOption?: string | number;
  className?: string;
  parentClassName?: string;
  onOpenChange?: (option: any) => void;
  placement?: 'bottom' | 'top';
};
const CustomDropdown: React.FC<TCustomDropdownProps> = ({
  options,
  defaultOption,
  className = '',
  parentClassName = '',
  onOpenChange,
  placement = 'bottom',
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (option: SetStateAction<string | number>) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (typeof options[0].value === 'string') {
      onOpenChange && onOpenChange(option);
    } else {
      onOpenChange && onOpenChange(Number(option));
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div
      className={cn('relative inline-block w-fit', parentClassName)}
      ref={dropdownRef}
    >
      <button
        className={cn(
          'flex h-7 w-14 items-center justify-between gap-3 rounded-full border bg-white px-2 text-sm leading-none outline-none',
          className,
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="capitalize">{selectedOption}</span>
        <span className="mt-px text-xs">
          <RiArrowUpSLine className="-mb-[5px]" />
          <RiArrowDownSLine className="" />
        </span>
      </button>
      {isOpen && (
        <div
          className={`absolute z-50 w-full overflow-hidden rounded-lg border-0 bg-white shadow-[0_0_15px_rgba(0,0,0,0.15)] dark:border dark:border-gray-800 dark:bg-gray-900 ${
            placement === 'top' ? 'bottom-full' : ''
          }`}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className="cursor-pointer px-3 py-1 text-sm hover:bg-black/5"
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
