import { formatDate, formatTime } from '@/utils/formateDate';

export default function DestinationArrivalInfo({
  allData,
  itemData,
  route,
  className,
}: any) {
  const airportNames = allData ? allData?.airport : itemData?.airport;

  const lastFlightDetail =
    route?.FlightDetails && route?.FlightDetails.length > 0
      ? route?.FlightDetails[route?.FlightDetails.length - 1]
      : undefined;

  const destinationFromFlightDetail = lastFlightDetail?.destination;
  const destinationFromRoute = route?.destination;

  const airportDestinationCity = airportNames
    ? airportNames[destinationFromFlightDetail || destinationFromRoute]
    : null;

  return (
    <div className={`text-end font-medium text-gray-600 ${className}`}>
      {/* ---> Arrive Time <-- */}
      <h3 className="time-of-arrival font-bold lg:text-lg">
        {formatTime(
          route?.FlightDetails[route?.FlightDetails.length - 1]?.arrival,
        )}
      </h3>

      {/* ---> Arrive Date <-- */}
      <h3 className="date-of-arrival whitespace-nowrap text-[10px] md:text-xs">
        {formatDate(
          route?.FlightDetails[route?.FlightDetails.length - 1]?.arrival,
        )}
      </h3>

      {/* ---> Arrive Destination <--- */}
      <h3 className="mt-1 whitespace-nowrap text-xs">
        {airportDestinationCity?.name?.length > 20 ? (
          <>
            <span className="hidden md:block">
              {airportDestinationCity ? airportDestinationCity.city : ''} (
              {
                route?.FlightDetails[route?.FlightDetails.length - 1]
                  ?.destination
              }
              ) - {airportDestinationCity?.name.slice(0, 20)}..
            </span>
            <span className="md:hidden">
              {airportDestinationCity ? airportDestinationCity.city : ''} (
              {
                route?.FlightDetails[route?.FlightDetails.length - 1]
                  ?.destination
              }
              )
            </span>
          </>
        ) : (
          <>
            <span className="hidden md:block">
              {airportDestinationCity ? airportDestinationCity.city : ''} (
              {
                route?.FlightDetails[route?.FlightDetails.length - 1]
                  ?.destination
              }
              ) - {airportDestinationCity?.name}
            </span>
            <span className="md:hidden">
              {airportDestinationCity ? airportDestinationCity.city : ''} (
              {
                route?.FlightDetails[route?.FlightDetails.length - 1]
                  ?.destination
              }
              )
            </span>
          </>
        )}
      </h3>
    </div>
  );
}
