'use client';

import BookingSteps from '@/components/layout/home/BookingSteps';
import Discover from '@/components/layout/home/Discover';
// import ExploreCities from "@/components/layout/home/ExploreCities"
import Footer from '@/components/layout/footer/Footer';
import HeroBanner from '@/components/layout/home/HeroBanner';
// import PopularDestination from '@/components/layout/home/PopularDestination';
import TopAirlines from '@/components/layout/home/TopAirlines';
// import TopFlightRoutes from "@/components/layout/home/TopFlightRoutes"
import { useAppSelector } from '@/store/hooks';

export default function HomePage() {
  const userRole = useAppSelector((state: any) => state.userData?.user?.role);

  if (
    userRole === 'SUDO' ||
    userRole === 'ADMIN' ||
    userRole === 'MOD' ||
    userRole === 'B2B'
  ) {
    return (
      <>
        <HeroBanner />
        <Footer />
      </>
    );
  }

  return (
    <>
      <HeroBanner />
      <TopAirlines />
      {/* <PopularDestination /> */}
      {/* <ExploreCities /> */}
      {/* <TopFlightRoutes /> */}
      <BookingSteps />
      <Discover />
      <Footer />
    </>
  );
}
