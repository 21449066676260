import { cn } from '@/utils/utils';

interface CustomCellProps {
  className?: string;
  title?: string;
  children?: React.ReactNode;
}
const CustomTCell: React.FC<CustomCellProps> = ({
  className = '',
  children,
  title = '',
  ...rest
}) => {
  return (
    <div
      {...rest}
      title={title}
      className={cn('flex min-h-10 items-center font-medium', className)}
    >
      {children}
    </div>
  );
};

export default CustomTCell;
