import Headline from './Headline';
import { MdArrowForwardIos } from '@Icons/md';
import Image from 'next/image';
import { topAirlinesData } from '@/constants/topAirlines';

export default function TopAirlines() {
  return (
    <div className="bg-white py-20">
      <div className="custom-container">
        <Headline
          title="Top Airlines"
          desc="Book cheap flights for your favourite airlines"
        />

        <div className="mt-10 grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-4 2xl:grid-cols-4">
          {topAirlinesData?.map((airline) => (
            <div
              key={airline.id}
              className="group flex cursor-pointer gap-2 rounded-lg bg-gray-50 pr-2"
            >
              <div className="flex size-14 min-h-14 min-w-14 items-center justify-center">
                <Image
                  alt="airline"
                  src={airline.image}
                  height={64}
                  width={64}
                  className="size-10 min-h-10 min-w-10 rounded-full bg-white object-contain"
                />
              </div>
              <div className="flex w-full items-center justify-between gap-1">
                <h1 className="text-sm font-medium">{airline.name}</h1>
                <div className="flex size-10 min-h-10 min-w-10 items-center justify-center overflow-hidden rounded-full bg-white text-gray-500 duration-100 ease-linear group-hover:bg-primary_color">
                  <span className="-translate-x-full duration-500 ease-in-out group-hover:translate-x-2">
                    <MdArrowForwardIos className="group-hover:text-white" />
                  </span>
                  <span className="-translate-x-2 duration-500 ease-in-out group-hover:translate-x-full">
                    <MdArrowForwardIos className="group-hover:text-white" />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
