export function calculateDuration(departure: any, arrival: any): string {
  if (!departure || !arrival) return 'Invalid input';

  const [departureDate, departureTime] = departure.split('T');
  const [arrivalDate, arrivalTime] = arrival.split('T');

  const departureDateTime = new Date(`${departureDate}T${departureTime}`);
  const arrivalDateTime = new Date(`${arrivalDate}T${arrivalTime}`);

  let durationMilliseconds =
    arrivalDateTime.getTime() - departureDateTime.getTime();

  if (durationMilliseconds < 0) {
    durationMilliseconds += 24 * 60 * 60 * 1000; // add one day if the arrival time is before the departure time
  }

  const durationHours = Math.floor(durationMilliseconds / (1000 * 60 * 60));
  durationMilliseconds %= 1000 * 60 * 60;
  const durationMinutes = Math.floor(durationMilliseconds / (1000 * 60));

  return `${durationHours}h ${durationMinutes}m`;
}
