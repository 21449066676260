import React from 'react';
import {
  RiArrowLeftDoubleLine,
  RiArrowLeftSLine,
  RiArrowRightDoubleLine,
  RiArrowRightSLine,
} from '@/Icons/ri';

interface PaginationProps {
  currentPage: number;
  setCurrentPage: (value: any) => void;
  totalPages: number;
}
const CustomPagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  return (
    <div className="flex items-center justify-center gap-5">
      <p className="text-xs font-medium text-gray-500">
        Page{' '}
        <span className="text-[13px] font-semibold">
          {totalPages ? currentPage : 0}
        </span>{' '}
        of <span className="text-[13px] font-semibold">{totalPages || 0}</span>
      </p>
      <div className="flex items-center gap-1 text-xs">
        <button
          onClick={() => {
            totalPages > 0 &&
              setCurrentPage(currentPage - 5 > 1 ? currentPage - 5 : 1);
            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // Use 'auto' for an instant scroll
            });
          }}
          disabled={totalPages < 1 || currentPage < 5}
          className="flex size-7 items-center justify-center rounded-full border border-gray-300 bg-white text-lg disabled:cursor-not-allowed disabled:text-gray-300 disabled:opacity-50"
        >
          <RiArrowLeftDoubleLine />
        </button>
        <button
          onClick={() => {
            totalPages > 0 && setCurrentPage(currentPage - 1);
            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // Use 'auto' for an instant scroll
            });
          }}
          disabled={totalPages < 1 || currentPage === 1}
          className="flex size-7 items-center justify-center rounded-full border border-gray-300 bg-white text-lg disabled:cursor-not-allowed disabled:text-gray-300 disabled:opacity-50"
        >
          <RiArrowLeftSLine />
        </button>

        <button
          onClick={() => {
            totalPages > 0 && setCurrentPage(currentPage + 1);
            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // Use 'auto' for an instant scroll
            });
          }}
          disabled={totalPages < 1 || currentPage === totalPages}
          className="flex size-7 items-center justify-center rounded-full border border-gray-300 bg-white text-lg disabled:cursor-not-allowed disabled:text-gray-300 disabled:opacity-50"
        >
          <RiArrowRightSLine />
        </button>
        <button
          onClick={() => {
            totalPages > 0 &&
              setCurrentPage(
                currentPage + 5 < totalPages ? currentPage + 5 : totalPages,
              );

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: 'smooth', // Use 'auto' for an instant scroll
            });
          }}
          disabled={totalPages < 1 || currentPage + 5 > totalPages}
          className="flex size-7 items-center justify-center rounded-full border border-gray-300 bg-white text-lg disabled:cursor-not-allowed disabled:text-gray-300 disabled:opacity-50"
        >
          <RiArrowRightDoubleLine />
        </button>
      </div>
    </div>
  );
};

export default CustomPagination;
